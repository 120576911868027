import { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'

import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { useThemes } from '@/context/ThemeContext'

import ModalSearchBlog from '../../ModalSearchBlog'
import PageSettings from '../../PageSettings'
import Footer from '../Footer'
import Navbar from '../Navbar'

import { type BlogLayoutProps } from './types'

const BlogLayout = ({ menus, wrapperClassName, children }: BlogLayoutProps) => {
  const { t } = useTranslation()
  const { themes } = useThemes()

  const MENU_TRANSLATION: Record<string, string> = t('blog:menu', {
    returnObjects: true,
  })

  const [isModalSearchOpen, setModalSearchOpen] = useState(false)

  return (
    <>
      <Navbar
        logoUrl={`/images/${themes.color}/main_logo-48.png`}
        logoAlt="Nur Muhamad Ash Shidiqi"
        menus={menus}
        navPosition="end"
        menuTranslation={MENU_TRANSLATION}
        endComponent={
          <button
            onClick={() => {
              setModalSearchOpen(true)
            }}
            aria-label={String(t('blog:search_in_blog'))}
            className="my-transition p-2 hover:text-primary dark:text-light"
          >
            <AiOutlineSearch size={24} />
          </button>
        }
      />

      <PageSettings />

      <ModalSearchBlog
        isOpen={isModalSearchOpen}
        onClose={() => {
          setModalSearchOpen(false)
        }}
      />

      <div
        className={cn(
          'text-color w-full bg-white dark:bg-dark',
          wrapperClassName,
        )}
      >
        {children}
      </div>

      <Footer />
    </>
  )
}

export default BlogLayout
