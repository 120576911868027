import { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import NavElement from '@/components/NavElement'
import { PAGES } from '@/utils/constants/pages'

import { type NavbarProps } from './types'

const Navbar = ({
  className,
  logoUrl,
  logoAlt,
  logoText,
  navPosition = 'center',
  menus,
  menuTranslation,
  endComponent,
  endComponentClassName,
}: NavbarProps) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleScroll = () => {
    if (window.scrollY > 5) setIsScrolled(true)
    if (window.scrollY <= 5) setIsScrolled(false)
  }

  useEffect(() => {
    if (window != null) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', () => '')
    }
  }, [])

  return (
    <div
      className={cn(
        className,
        'my-transition page-padding fixed left-0 top-0 z-20 flex w-screen items-center justify-between !py-4',
        {
          'border-b-2 bg-white dark:border-white/20 dark:bg-dark': isScrolled,
        },
      )}
    >
      <div
        className={cn(
          className,
          'flex w-full flex-wrap items-center justify-between',
        )}
      >
        <div className="flex items-center gap-4">
          {logoUrl != null && (
            <Link
              href={PAGES.home.path}
              aria-label={String(t('blog:back_to_home'))}
            >
              <Image
                src={logoUrl}
                alt={logoAlt != null ? logoAlt : 'Logo'}
                height={36}
                width={36}
                priority
              />
            </Link>
          )}
          <span
            className={cn('font-normal text-primary-text dark:text-light', {
              hidden: logoText == null,
            })}
          >
            {logoText}
          </span>
        </div>

        {/* Desktop only */}
        <NavElement
          menus={menus}
          menuTranslation={menuTranslation}
          className={cn('!hidden lg:!flex', {
            'ml-auto mr-8': navPosition === 'end',
            'ml-8 mr-auto': navPosition === 'start',
          })}
        />

        <div
          className={cn(
            'hidden w-full items-center border-t-2 lg:flex lg:w-max lg:border-t-0',
            endComponentClassName,
          )}
        >
          {endComponent}
        </div>
        {/* Desktop only */}

        {/* For Mobile Device */}
        <div className="lg:hidden">
          <button
            className="p-2 text-primary-text"
            aria-label={String(
              isOpen ? t('common:close_menu') : t('common:open_menu'),
            )}
            onClick={toggleMenu}
          >
            <AiOutlineMenu size={24} />
          </button>
        </div>
      </div>

      {/* drawer only for mobile */}
      <div
        className={cn(
          'my-transition fixed left-0 top-0 z-30 h-screen w-screen duration-200 lg:hidden',
          {
            '-translate-x-full bg-neutral-950/0 delay-200': !isOpen,
            'translate-x-0 bg-neutral-950/50 delay-0': isOpen,
          },
        )}
      />
      <div
        className={cn(
          'my-transition fixed right-0 top-0 z-30 h-screen w-5/6 rounded-tl-[32px] border bg-white duration-200 lg:hidden',
          'dark:border-light/20 dark:bg-dark',
          {
            'translate-x-full delay-0': !isOpen,
            'translate-x-0 delay-200': isOpen,
          },
        )}
      >
        <div className="block h-24 p-4">
          <button
            aria-label={String(t('common:close_menu'))}
            onClick={toggleMenu}
            className="p-2 text-primary-text hover:bg-slate-200 dark:hover:bg-light/20"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>
        <div className="block h-full overflow-y-auto pb-24 pt-4">
          <NavElement
            menus={menus}
            menuTranslation={menuTranslation}
            direction="vertical"
          />
          <div
            className={cn(
              'mt-8 flex w-full flex-col items-center border-t-2 py-8 dark:border-light/20',
              endComponentClassName,
            )}
          >
            {endComponent}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
