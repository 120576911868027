export const SOCIAL_LINK = {
  ig: 'https://www.instagram.com/nurmuhamadas',
  yt: 'https://www.youtube.com/nurmuhamadas',
  mail: 'mailto:nurmuhamad.a.13@gmail.com',
  wa: 'https://www.wa.me/6285655350504',
  github: 'https://www.github.com/nurmuhamadas',
  linkedin: 'https://www.linkedin.com/in/nurmuhamadas/',
  cv: 'https://drive.google.com/drive/folders/1pt3Ug97s41zsTN53pmAX7w_3dSIJk5Ll?usp=sharing',
  upwork: 'https://www.upwork.com/freelancers/~019597f410be18ce47',
  nusantech:
    'https://drive.google.com/file/d/1fqvUMgDuzMcjDI0twFnxom9vD4wIuIPo/view?usp=sharing',
}

export const LANG = [
  {
    id: 'en',
    text: 'English',
  },
  {
    id: 'id',
    text: 'Bahasa',
  },
]
