import { useEffect, useState } from 'react'

import { type UseWindowResize } from './types'

export const useWindowResize = (): UseWindowResize => {
  const [value, setValue] = useState<UseWindowResize>({
    width: 0,
    height: 0,
  })

  const handleChange = () => {
    setValue({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    if (window != null) {
      handleChange()
      window.addEventListener('resize', handleChange)
    }

    return () => {
      window.removeEventListener('resize', handleChange)
    }
  }, [])

  return value
}
