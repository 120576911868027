import cn from 'classnames'

import { SOCIAL_MEDIA } from './consts'
import { type SocialIconsProps } from './types'

const SocialIcons = ({
  classNames,
  show = {},
  withBg = false,
}: SocialIconsProps) => {
  return (
    <div
      className={cn(
        'flex flex-wrap items-center justify-center gap-4',
        classNames,
      )}
    >
      {SOCIAL_MEDIA.map(({ id, label, link, Icon }) => {
        if (show[id] == null) {
          return null
        }

        return (
          <a
            key={id}
            target="_blank"
            rel="noopener noreferrer"
            className={cn('my-transition rounded-xl', {
              'bg-primary p-2 text-white hover:bg-accent active:bg-primary-active dark:text-dark dark:hover:text-white':
                withBg,
              'text-primary hover:text-accent': !withBg,
            })}
            href={link}
            aria-label={label}
          >
            <Icon size={32} />
          </a>
        )
      })}
    </div>
  )
}

export default SocialIcons
