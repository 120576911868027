import { PAGES } from './pages'
import { type MenuType } from './types'

export const MAIN_MENU: MenuType[] = [
  {
    text: PAGES.home.title,
    link: PAGES.home.path,
  },
  {
    text: PAGES.about.title,
    link: PAGES.about.path,
  },
  {
    text: PAGES.portfolio.title,
    link: PAGES.portfolio.path,
  },
  {
    text: PAGES.ido.title,
    link: PAGES.ido.path,
  },
  {
    text: PAGES.resource.title,
    link: PAGES.resource.path,
  },
  {
    text: PAGES.blog.title,
    link: PAGES.blog.path,
    hide: true,
  },
]

export const BLOG_MENU: MenuType[] = [
  {
    text: PAGES.blog.title,
    link: PAGES.blog.path,
  },
  {
    text: PAGES.blog_tags.title,
    link: PAGES.blog_tags.path,
  },
  {
    text: PAGES.blog_bookmarks.title,
    link: PAGES.blog_bookmarks.path,
  },
  {
    text: PAGES.buy_coffee.title,
    link: PAGES.buy_coffee.path,
  },
]

export const ADMIN_MENU: MenuType[] = [
  {
    text: PAGES.admin_dashboard.title,
    link: PAGES.admin_dashboard.path,
  },
  {
    text: PAGES.admin_personal.title,
    link: PAGES.admin_personal.title,
  },
]
