import Link from 'next/link'
import { useRouter } from 'next/router'

import cn from 'classnames'

import { OPENSANS_BOLD } from '@/utils/constants/themes'

import { type NavElementProps } from './types'

const NavElement = ({
  className,
  direction = 'horizontal',
  menus,
  menuTranslation,
}: NavElementProps) => {
  const { pathname } = useRouter()

  const checkMenuActive = (pathname: string, link: string) => {
    if (link === '/') {
      return link === pathname
    }
    return pathname.startsWith(link)
  }

  const getCommonClasses = ({
    isMenuActive,
    isHide,
  }: {
    isMenuActive: boolean
    isHide: boolean
  }) => {
    return [
      'relative active:text-primary-active',
      {
        'cursor-default text-primary-text dark:text-primary': isMenuActive,
        [OPENSANS_BOLD.className]: isMenuActive,
        'hover:text-primary': !isMenuActive,
        hidden: isHide,
      },
    ]
  }

  return (
    <nav
      className={cn('flex items-center', className, {
        'justify-center gap-8': direction === 'horizontal',
        'flex-col gap-6': direction === 'vertical',
      })}
    >
      {[...menus].map(({ text, link, hide }) => {
        return (
          <Link
            href={link}
            key={link}
            className={cn(
              getCommonClasses({
                isMenuActive: checkMenuActive(pathname, link),
                isHide: hide ?? false,
              }),
            )}
          >
            {menuTranslation[text]}
          </Link>
        )
      })}
    </nav>
  )
}

export default NavElement
