import { type PageType } from './types'

export const PAGES: Record<string, PageType> = {
  home: {
    title: 'home',
    path: '/',
    layout: 'main-layout',
  },
  about: {
    title: 'about',
    path: '/about',
    layout: 'main-layout',
  },
  portfolio: {
    title: 'portfolio',
    path: '/portfolio',
    layout: 'main-layout',
  },
  ido: {
    title: 'ido',
    path: '/what-i-do',
    layout: 'main-layout',
  },
  resource: {
    title: 'resource',
    path: '/resource',
    layout: 'main-layout',
  },
  blog: {
    title: 'blog',
    path: '/blog',
    layout: 'blog-layout',
  },
  works: {
    title: 'works',
    path: '/works',
    layout: 'main-layout',
  },
  buy_coffee: {
    title: 'buy_coffee',
    path: '/buy-coffee',
    layout: 'blog-layout',
  },

  // BLOG PAGE
  blog_detail: {
    title: 'blog_detail',
    path: '/blog/{id}',
    layout: 'blog-layout',
  },
  blog_tags: {
    title: 'blog_tags',
    path: '/blog/tags',
    layout: 'blog-layout',
  },
  blog_bookmarks: {
    title: 'blog_bookmarks',
    path: '/blog/bookmarks',
    layout: 'blog-layout',
  },
  blog_explore: {
    title: 'blog_explore',
    path: '/blog/explore',
    layout: 'blog-layout',
  },

  // ADMIN ONLY PAGE
  admin_login: {
    title: 'admin_login',
    path: '/admin/login',
    layout: 'no-layout',
  },
  admin_dashboard: {
    title: 'dashboard',
    path: '/admin',
    layout: 'admin-layout',
    private: true,
  },
  admin_personal: {
    title: 'personal',
    path: '/admin/personal',
    layout: 'admin-layout',
    private: true,
  },
}
