import Link from 'next/link'

import cn from 'classnames'

import { ROBOTO_LIGHT } from '@/utils/constants/themes'

import { type ButtonProps } from './types'

const Child = ({
  iconPosition,
  children,
  icon,
}: Pick<ButtonProps, 'iconPosition' | 'children' | 'icon'>) => (
  <>
    <span className={cn({ hidden: iconPosition !== 'start' })}>{icon}</span>
    {children}
    <span className={cn({ hidden: iconPosition !== 'end' })}>{icon}</span>
  </>
)

const Button = ({
  children,
  type = 'primary',
  rounded,
  size,
  icon,
  iconPosition = 'start',
  asLink,
  buttonProps = {},
  linkProps,
}: ButtonProps) => {
  const CLASSNAME = cn(
    'flex h-max w-max items-center gap-x-1 rounded px-4 py-2 font-roboto transition-all duration-300 ease-in-out disabled:bg-disabled',
    {
      [`bg-primary-text text-white hover:bg-primary active:bg-primary-active ${ROBOTO_LIGHT.className}`]:
        type === 'primary',
      'border border-primary bg-opacity-0 text-primary-text hover:bg-mirror active:bg-mirror':
        type === 'outline',
      'bg-opacity-0 text-primary-text hover:bg-primary-text hover:text-white':
        type === 'text',
      'bg-gradient-to-r from-primary to-secondary text-white hover:from-primary-hover hover:to-secondary':
        type === 'gradient',

      // Size style
      'text-sm': size === 'sm',
      'text-lg': size === 'lg',

      // rounded
      'rounded-full px-5': rounded,
    },
  )

  const child = (
    <Child icon={icon} iconPosition={iconPosition}>
      {children}
    </Child>
  )

  if (asLink === true) {
    const { href, className, ...resProps } = linkProps

    if (typeof href === 'string' && !href.startsWith('/')) {
      return (
        <a href={href} className={cn(className, CLASSNAME)} {...resProps}>
          {child}
        </a>
      )
    }

    return (
      <Link href={href} className={cn(className, CLASSNAME)} {...resProps}>
        {child}
      </Link>
    )
  }

  if (!asLink) {
    const { className, ...resProps } = buttonProps
    return (
      <button className={cn(className, CLASSNAME)} {...resProps}>
        {child}
      </button>
    )
  }

  return null
}

export default Button
