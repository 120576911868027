import Head from 'next/head'
import { useRouter } from 'next/router'

import { useThemes } from '@/context/ThemeContext'

import { GLOBAL_META } from './consts'
import { type SEOHeadProps } from './types'

const SEOHead = ({
  title,
  description,
  robots,
  ogType = 'website',
  siteLogo,
  favicon,
  keywords,
  children,
}: SEOHeadProps) => {
  const { locale } = useRouter()
  const { themes } = useThemes()
  const META = GLOBAL_META[locale as 'en' | 'id']

  return (
    <Head>
      <title>{title ?? META.title}</title>
      <meta charSet="UTF-8" key="charset" />
      <meta
        name="viewport"
        key="viewport"
        content="width=device-width, initial-scale=1.0"
      />
      <meta name="robots" key="robots" content={robots ?? META.robots} />

      <meta name="title" key="title" content={title ?? META.title} />
      <meta
        name="description"
        key="description"
        content={description ?? META.desc}
      />
      <meta
        name="keywords"
        key="keyowrds"
        content={keywords ?? META.keywords}
      />
      <meta name="author" key="author" content="Nur Muhamad Ash Shidiqi" />

      {/* Social Media */}
      <meta name="og:title" key="og:title" content={title ?? META.title} />
      <meta
        name="og:description"
        key="og:description"
        content={description ?? META.desc}
      />
      <meta
        name="og:image"
        key="og:image"
        content={siteLogo ?? META.siteLogo(themes.color)}
      />
      <meta property="og:type" key="og:type" content={ogType} />

      <meta
        name="twitter:title"
        key="twitter:title"
        content={title ?? META.title}
      />
      <meta
        name="twitter:description"
        key="twitter:description"
        content={description ?? META.desc}
      />
      <meta
        name="twitter:image"
        key="twitter:image"
        content={siteLogo ?? META.siteLogo(themes.color)}
      />

      {/* Favicon */}
      <link rel="shortcut icon" href={favicon ?? META.favicon(themes.color)} />

      {/* Additional tags */}
      {children}
    </Head>
  )
}

export default SEOHead
