import { useEffect, useRef, useState } from 'react'
import { AiOutlineClose, AiOutlineSetting } from 'react-icons/ai'

import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { useWindowResize } from '@/hooks/useWindowResize'

import Fab from '../Fab'

import { type PageSettingsProps } from './types'

const MenuComponent = dynamic(
  async () => await import('./Partials/MenuComponent'),
)

const PageSettings = ({ popoverClassName }: PageSettingsProps) => {
  const { t } = useTranslation(['common', 'menu'])
  const { width } = useWindowResize()

  const fabRef = useRef<HTMLButtonElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const [fabPosition, setFabPosition] = useState({ top: 0, left: 0 })
  const [openPopover, setOpenPopover] = useState(false)

  // SET POSITION SECTION
  const handleSetPosition = () => {
    const fabEl = fabRef.current
    if (fabEl != null) {
      const { top, left } = fabEl.getBoundingClientRect()
      setFabPosition({ top, left })
    }
  }

  useEffect(() => {
    handleSetPosition()
  }, [fabRef, width])

  // CLICK OUTSIDE SECTION
  const handleClickOutside = (e: globalThis.MouseEvent) => {
    if (
      openPopover &&
      popoverRef.current != null &&
      fabRef.current != null &&
      !popoverRef.current.contains(e.target as Node) &&
      !fabRef.current.contains(e.target as Node)
    ) {
      setOpenPopover(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', (e) => {
      handleClickOutside(e)
    })

    return () => {
      document.removeEventListener('click', (e) => {
        handleClickOutside(e)
      })
    }
  }, [openPopover])

  return (
    <>
      <Fab
        ref={fabRef}
        icon={openPopover ? AiOutlineClose : AiOutlineSetting}
        className="bottom-12 right-6"
        iconProps={{
          className: cn({ 'animate-spin-delay': !openPopover }),
          size: 24,
        }}
        onClick={() => {
          setOpenPopover(!openPopover)
        }}
        aria-label={String(
          openPopover
            ? t('common:close_page_setting')
            : t('common:open_page_setting'),
        )}
      />
      <MenuComponent
        containerRef={popoverRef}
        style={fabPosition}
        isOpen={openPopover}
        className={popoverClassName}
      />
    </>
  )
}

export default PageSettings
