import {
  AiFillLinkedin,
  AiOutlineGithub,
  AiOutlineInstagram,
  AiOutlineMail,
  AiOutlineYoutube,
} from 'react-icons/ai'

import { SOCIAL_LINK } from '@/utils/constants/common'

import { type SocialIconType } from './types'

export const SOCIAL_MEDIA: SocialIconType[] = [
  {
    id: 'ig',
    label: 'Instagram',
    link: SOCIAL_LINK.ig,
    Icon: AiOutlineInstagram,
  },
  {
    id: 'yt',
    label: 'Youtube',
    link: SOCIAL_LINK.yt,
    Icon: AiOutlineYoutube,
  },
  {
    id: 'github',
    label: 'Github',
    link: SOCIAL_LINK.github,
    Icon: AiOutlineGithub,
  },
  {
    id: 'linkedin',
    label: 'Linkedin',
    link: SOCIAL_LINK.linkedin,
    Icon: AiFillLinkedin,
  },
  {
    id: 'mail',
    label: 'Email',
    link: SOCIAL_LINK.mail,
    Icon: AiOutlineMail,
  },
  // {
  //   id: 'wa',
  //   label: 'Whatsapp',
  //   link: SOCIAL_LINK.wa,
  //   Icon: AiOutlineWhatsApp,
  // },
]
