import { type ColorsType } from '@/context/ThemeContext/types'

export const GLOBAL_META = {
  en: {
    robots: 'index, follow',
    title: "Nur Muhamad Ash Shidiqi's portfolio website",
    desc: 'Show Case profile, project, resource, and blog of Nur Muhamad Ash Shidiqi, professional web developer with +2 years experience',
    siteLogo: (color: ColorsType) => `/images/${color}/main_logo.webp`,
    favicon: (color: ColorsType) => `/icons/${color}/favicon.ico`,
    siteName: "Nur Muhamad Ash Shidiqi's portfolio website",
    keywords:
      'Nur Muhamad Ash Shidiqi, nurmuhamadas, Sofware Engineer, Front End Web, Full Stack Developer, Portfolio page, project show case',
  },
  id: {
    robots: 'index, follow',
    title: 'Website portfolio Nur Muhamad Ash Shidiqi',
    desc: 'Website yang memuat profil, projek, dan blog dari Nur Muhamad Ash Shidiqi, seorang sofware engineer profesional dengan pengalaman lebih dari 2 tahun',
    siteLogo: (color: ColorsType) => `/images/${color}/main_logo.webp`,
    favicon: (color: ColorsType) => `/icons/${color}/favicon.ico`,
    siteName: 'Website portfolio Nur Muhamad Ash Shidiqi',
    keywords:
      'Nur Muhamad Ash Shidiqi, nurmuhamadas, Sofware Engineer, Front End Web, Full Stack Developer, Portfolio page, project show case',
  },
}
