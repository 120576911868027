import { type ThemesReducerType } from './types'

export const themesReducer: ThemesReducerType = (state, action) => {
  switch (action.type) {
    case 'CHANGE_COLOR':
      return {
        ...state,
        color: action.value,
      }

    case 'CHANGE_THEME':
      return {
        ...state,
        theme: action.value,
      }

    default:
      return state
  }
}
