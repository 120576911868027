import { createContext, useContext, useEffect, useReducer } from 'react'

import cn from 'classnames'

import SEOHead from '@/components/SEOHead'
import { GLOBAL_META } from '@/components/SEOHead/consts'
import {
  INITIAL_THEMES,
  OPENSANS,
  OPENSANS_LIGHT,
  ROBOTO,
  themesStorageKey,
} from '@/utils/constants/themes'

import { themesReducer } from './reducer'
import {
  type ColorsType,
  type MainThemesType,
  type ThemesContextProps,
  type ThemesContextType,
  type ThemesType,
} from './types'

export const ThemesContext = createContext<ThemesContextType>({
  themes: INITIAL_THEMES,
  dispatchTheme: () => '',
  changeColorHandler: () => '',
  changeThemeHandler: () => '',
})

const ThemesProvider = ({ children }: ThemesContextProps): JSX.Element => {
  const [themes, dispatchTheme] = useReducer(themesReducer, INITIAL_THEMES)

  const changeLocalstorage = (
    key: 'color' | 'theme',
    value: ColorsType | ThemesType,
  ) => {
    localStorage.setItem(
      themesStorageKey,
      JSON.stringify({
        ...themes,
        [key]: value,
      }),
    )
  }

  const changeThemeHandler = (value: ThemesType) => {
    dispatchTheme({ type: 'CHANGE_THEME', value })
    changeLocalstorage('theme', value)
  }

  const changeColorHandler = (value: ColorsType) => {
    dispatchTheme({ type: 'CHANGE_COLOR', value })
    changeLocalstorage('color', value)
  }

  useEffect(() => {
    const localThemes = localStorage.getItem(themesStorageKey)

    if (localThemes) {
      const parsedTheme: MainThemesType = JSON.parse(localThemes)
      dispatchTheme({ type: 'CHANGE_COLOR', value: parsedTheme.color })
      dispatchTheme({ type: 'CHANGE_THEME', value: parsedTheme.theme })
    }
  }, [])

  return (
    <ThemesContext.Provider
      value={{ themes, dispatchTheme, changeColorHandler, changeThemeHandler }}
    >
      <SEOHead favicon={GLOBAL_META.en.favicon(themes.color)} />
      <div
        className={cn(
          `my-transition h-full w-full overflow-x-hidden font-sans`,
          OPENSANS.variable,
          OPENSANS_LIGHT.variable,
          ROBOTO.variable,
          {
            'bg-white': themes.theme === 'light',
          },
          { [`bg-dark ${OPENSANS_LIGHT.className}`]: themes.theme === 'dark' },
        )}
        data-theme={themes.theme}
        data-color={themes.color}
      >
        {children}
      </div>
    </ThemesContext.Provider>
  )
}

export const useThemes = () => {
  return useContext<ThemesContextType>(ThemesContext)
}

export default ThemesProvider
