import { Fragment, useEffect, useMemo } from 'react'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'

import { init } from 'aos'

import AdminLayout from '@/components/Layout/AdminLayout'
import BlogLayout from '@/components/Layout/BlogLayout'
import MainLayout from '@/components/Layout/MainLayout'
import Provider from '@/components/Provider'
import SEOHead from '@/components/SEOHead'
import { BLOG_MENU, MAIN_MENU } from '@/utils/constants/menus'
import { getLayoutFromPath } from '@/utils/helper/pages'

import 'aos/dist/aos.css'
import '@/styles/globals.css'

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const layout = useMemo(() => {
    return getLayoutFromPath(router.pathname)
  }, [router.pathname])

  useEffect(() => {
    init({
      easing: 'ease-out-cubic',
      offset: 50,
      delay: 50,
    })
  }, [])

  return (
    <Fragment>
      <SEOHead />
      <Provider>
        {layout === 'main-layout' && (
          <MainLayout menus={MAIN_MENU}>
            <Component {...pageProps} />
          </MainLayout>
        )}
        {layout === 'blog-layout' && (
          <BlogLayout menus={BLOG_MENU}>
            <Component {...pageProps} />
          </BlogLayout>
        )}
        {layout === 'admin-layout' && (
          <AdminLayout menus={BLOG_MENU}>
            <Component {...pageProps} />
          </AdminLayout>
        )}
        {(!layout || layout === 'no-layout') && <Component {...pageProps} />}
      </Provider>
    </Fragment>
  )
}

export default appWithTranslation(App)
