import { forwardRef } from 'react'

import cn from 'classnames'

import { type FabProps, type FabRefType } from './types'

const mapSize = {
  sm: 28,
  md: 32,
  lg: 36,
}

const Fab = (
  { className, size = 'md', icon, iconProps = {}, ...resProps }: FabProps,
  ref: FabRefType,
) => {
  return (
    <button
      ref={ref}
      className={cn(
        className,
        'shadow-primary-md dark:shadow-primary-md-dark fixed z-10 flex items-center justify-center rounded-full bg-white p-4 text-primary transition-all duration-300 ease-out hover:bg-slate-100 dark:bg-dark',
      )}
      {...resProps}
    >
      {icon({
        size: mapSize[size],
        ...iconProps,
      })}
    </button>
  )
}

export default forwardRef(Fab)
