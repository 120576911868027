import { PAGES } from '../constants/pages'
import { type AvailableLayout, type TagsDataType } from '../constants/types'

export const mapTags: (d: TagsDataType[]) => string[] = (d) => {
  const _tags: string[] = []

  d.forEach((d) => {
    _tags.push(d.name)
    if (d.child) _tags.push(...mapTags(d.child))
  })

  return _tags
}

export const tagToParams = (tag: string) => {
  return tag.replaceAll(' ', '-').toLowerCase()
}

export const getLayoutFromPath = (
  path: string,
): AvailableLayout | undefined => {
  const pages = Object.entries(PAGES).find(([_, v]) => v.path === path)
  return pages?.[1]?.layout
}
