import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai'

import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { type ModalSearchBlogProps } from './types'

const ModalSearchBlog = ({ isOpen, onClose }: ModalSearchBlogProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={cn(
        'my-transition page-padding fixed left-0 top-0 z-10 flex h-screen w-screen origin-top justify-center overflow-y-auto bg-dark/20 dark:bg-light/20',
        {
          'scale-0': !isOpen,
        },
      )}
    >
      <div
        className={cn(
          'relative z-20 flex w-full max-w-2xl flex-col rounded-3xl bg-white p-8 pt-4',
          'dark:bg-dark',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2 border-b-2 border-dark/25 py-1">
            <AiOutlineSearch size={20} className="text-dark/50" />
            <input
              autoFocus
              className="focus:outline-none"
              placeholder={`${t('common:search')}...`}
            />
          </div>
          <button
            aria-label={String(t('common:close_filter'))}
            onClick={onClose}
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        <div className="my-4 h-0 w-full border border-primary" />

        <div className="flex flex-col gap-y-4">Value here</div>
      </div>
    </div>
  )
}

export default ModalSearchBlog
