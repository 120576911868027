import cn from 'classnames'

import SocialIcons from '../../SocialIcons'

import { type FooterProps } from './types'

const Footer = ({ className }: FooterProps) => {
  return (
    <div
      className={cn(
        className,
        'flex h-max w-full flex-col items-center gap-y-9 bg-gradient-to-t from-primary/[0.15] to-white/0 px-6 py-16 lg:px-24',
        'dark:from-primary/20 dark:to-dark/0',
      )}
    >
      <div className="px-8">
        <SocialIcons
          withBg
          show={{
            ig: true,
            yt: true,
            github: true,
            linkedin: true,
            mail: true,
            wa: true,
          }}
        />
      </div>
      <span className="text-color text-center">
        Copyright {new Date().getFullYear()} © Nur Muhamad Ash Shidiqi
      </span>
    </div>
  )
}

export default Footer
