import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { useThemes } from '@/context/ThemeContext'
import { SOCIAL_LINK } from '@/utils/constants/common'

import Button from '../../Button'
import PageSettings from '../../PageSettings'
import Footer from '../Footer'
import Navbar from '../Navbar'

import { type MainLayoutProps } from './types'

const MainLayout = ({ menus, wrapperClassName, children }: MainLayoutProps) => {
  const { t } = useTranslation('common')
  const { themes } = useThemes()

  const MENU_TRANSLATION: Record<string, string> = t('common:main_menu', {
    returnObjects: true,
  })

  return (
    <>
      <Navbar
        logoUrl={`/images/${themes.color}/main_logo-48.png`}
        logoAlt="Nur Muhamad Ash Shidiqi"
        menus={menus}
        menuTranslation={MENU_TRANSLATION}
        endComponent={
          <Button asLink linkProps={{ href: SOCIAL_LINK.mail }} type="outline">
            {t('contact_me')}
          </Button>
        }
      />

      <PageSettings />

      <div
        className={cn(
          'text-color w-full bg-white dark:bg-dark',
          wrapperClassName,
        )}
      >
        {children}
      </div>

      <Footer />
    </>
  )
}

export default MainLayout
