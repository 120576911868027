import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { useThemes } from '@/context/ThemeContext'

import MenuList from './components/MenuList'
import { type SidebarProps } from './types'

const Sidebar = ({ className, isOpen, menu, onClose }: SidebarProps) => {
  const { t } = useTranslation()
  const { themes } = useThemes()

  return (
    <div
      className={cn(
        'my-transition border-r',
        {
          // 'translate-x-0': isOpen,
          // '-translate-x-full': !isOpen,
        },
        className,
      )}
    >
      <div
        className={cn(
          'flex h-max min-h-screen flex-col gap-y-12 bg-white px-6 py-8',
        )}
      >
        <div className="flex w-full items-center justify-between">
          <Image
            src={`/images/${themes.color}/main_logo-48.png`}
            alt={"Nur Muhamad Ash Shidiqi's Logo"}
            height={36}
            width={36}
            priority
          />
        </div>

        <MenuList menu={menu} />
      </div>

      <button
        onClick={onClose}
        className="absolute right-0 top-0 translate-x-[41px] translate-y-24 rounded-r-lg border bg-white p-2 text-secondary hover:bg-light md:hidden"
        aria-label={String(t('common:close_menu'))}
      >
        <AiOutlineClose size={24} className={cn({ hidden: !isOpen })} />
        <AiOutlineMenu size={24} className={cn({ hidden: isOpen })} />
      </button>
    </div>
  )
}

export default Sidebar
