import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { type MenuListProps } from '../types'

const MenuList = ({ menu, className }: MenuListProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const menuText: Record<string, string> = t('admin:menu', {
    returnObjects: true,
  })
  return (
    <ul className={cn('flex w-full flex-col', className)}>
      {menu.map(({ link, text, child }) => {
        const isActive = router.pathname === link

        return (
          <li key={link} className="w-full px-4 py-3">
            {!child && (
              <Link
                href={link}
                className={cn('my-transition h-max w-max hover:text-primary', {
                  'cursor-default !text-primary-text': isActive,
                })}
              >
                {menuText[text]}
              </Link>
            )}

            {child && (
              <ul className="flex w-full flex-col pl-4">
                {child.map((m) => {
                  return (
                    <li key={m.link} className="w-full px-4 py-2">
                      <Link
                        href={link}
                        className={cn(
                          'my-transition h-max w-max text-sm hover:text-primary',
                          { 'cursor-default !text-primary-text': isActive },
                        )}
                      >
                        {menuText[m.text]}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default MenuList
