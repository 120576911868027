import { Open_Sans, Roboto } from 'next/font/google'

import {
  type ColorsType,
  type MainThemesType,
} from '@/context/ThemeContext/types'

export const ROBOTO_LIGHT = Roboto({
  subsets: ['latin'],
  weight: ['300'],
  variable: '--font-roboto-light',
})
export const ROBOTO = Roboto({
  subsets: ['latin'],
  weight: ['400'],
  variable: '--font-roboto',
})
export const ROBOTO_MEDIUM = Roboto({
  subsets: ['latin'],
  weight: ['500'],
  variable: '--font-roboto-medium',
})
export const ROBOTO_BOLD = Roboto({
  subsets: ['latin'],
  weight: ['700'],
  variable: '--font-roboto-bold',
})

export const OPENSANS_LIGHT = Open_Sans({
  subsets: ['latin'],
  weight: ['300'],
  variable: '--font-openSans-light',
})
export const OPENSANS = Open_Sans({
  subsets: ['latin'],
  weight: ['400'],
  variable: '--font-openSans',
})
export const OPENSANS_MEDIUM = Open_Sans({
  subsets: ['latin'],
  weight: ['500'],
  variable: '--font-openSans-Medium',
})
export const OPENSANS_BOLD = Open_Sans({
  subsets: ['latin'],
  weight: ['700'],
  variable: '--font-openSans-Bold',
})

// THEMES
export const COLORS: ColorsType[] = ['verdigris', 'avocado']

export const MAP_COLORS: Record<ColorsType, string> = {
  verdigris: '#00A8AD',
  avocado: '#6E7B30',
}
export const INITIAL_THEMES: MainThemesType = {
  color: 'verdigris',
  theme: 'light',
}

export const themesStorageKey = 'themes'
