import { useEffect, useState } from 'react'

import cn from 'classnames'

import PageSettings from '@/components/PageSettings'
import { useWindowResize } from '@/hooks/useWindowResize'
import { ADMIN_MENU } from '@/utils/constants/menus'

import Sidebar from '../Sidebar'

import { type AdminLayoutProps } from './types'

const AdminLayout = ({ wrapperClassName, children }: AdminLayoutProps) => {
  const { width } = useWindowResize()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [leftGap, setLeftGap] = useState(0)

  useEffect(() => {
    const _width = (width * 5) / 6
    if (_width > 320) {
      setLeftGap(320)
    } else {
      setLeftGap((width * 5) / 6)
    }
  }, [width])

  return (
    <div className="relative flex min-h-screen w-full overflow-x-hidden">
      <Sidebar
        isOpen={isSidebarOpen}
        menu={ADMIN_MENU}
        onClose={() => {
          setIsSidebarOpen(!isSidebarOpen)
        }}
        className={cn(
          'absolute left-0 top-0 z-20 w-5/6 max-w-xs',
          'md:translate-x-0',
          {
            '-translate-x-full': !isSidebarOpen,
            '': isSidebarOpen,
          },
        )}
      />

      <PageSettings />

      <div
        className={cn(
          'my-transition text-color w-max bg-white dark:bg-dark',
          wrapperClassName,
        )}
        style={{ marginLeft: isSidebarOpen || width >= 768 ? leftGap : 0 }}
      >
        <div className="min-h-screen w-screen px-6 py-8 sm:px-8 sm:py-10 md:w-full">
          {children}
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
